import React from 'react';
import { howItworksType } from '../../../templates/use-cases';
import './how-it-works.css';
// import { GatsbyImage } from 'gatsby-plugin-image';
import unifiedImage from '../../../assets/use-cases/unified-search.svg';
import edgeAutomationImage from '../../../assets/use-cases/edge-automation.svg';
import analyticsImage from '../../../assets/use-cases/analytics.svg';
import AppButton from '../../CustomComponents/app-button';

// temporaryly using img bcoz the image looks pixalated when using png/svg in StaticImage component even after setting quality high and
const AppImage = ({ image }: { image: string }) => {
    return <img src={image} alt="" style={{ width: '100%' }} loading="lazy" />;
};


function HowItWorks({ howItWorksImages }: { howItWorksImages?: howItworksType }) {
    const buttonProps = (labelText?: string) => {
        return {
            style: { padding: "0.5em 0.75em", marginTop: "0.75em", fontWeight: "normal" },
            variant: 'variant-teal',
            text: labelText ?? 'Learn More'
        }
    }
    return (
        <div className="howitworks2">
            <div className="parent-wrapper">
                <div className="content-container">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/887168040?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom  title">The Importance of AI Based Search</h2>
                        <p className=" hw-des">
                            Products change more rapidly then ever before, forcing agents to become instant experts. How do you quickly get
                            new product information into the hands of agents quickly?
                        </p>
                        {/* <AppButton {...buttonProps()} linkTo='/blog/benefits-knowledge-management-customer-service' /> */}
                    </div>
                </div>

                <div className="content-container isreversed">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/887168220?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom  title">Empower Agent Success With Accurate Answers</h2>
                        <p className=" hw-des">
                            The proliferation of information continues to make accurate answers more difficult to find. Parsing through disparate knowledge inevitably effects agent satisfaction as much as customer satisfaction.
                        </p>
                        {/* <AppButton {...buttonProps()} linkTo='/technology/gpt-answer-engine/' /> */}
                    </div>
                </div>
                <div className="content-container">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/887168166?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom  title">Investing In Time-to-Resolution</h2>
                        <p className=" hw-des">
                            We all want answers quickly and in many case can be the reason we have a great experience. Implementing the right tools can make all the difference.
                        </p>
                        {/* <AppButton {...buttonProps()} linkTo='/improved-resolution-time/' /> */}

                    </div>
                </div>
                <div className="content-container isreversed">
                    <div className="video-block2">
                        <iframe
                            title="vimeo-player"
                            src="https://player.vimeo.com/video/887174331?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className='hw-text-box'>
                        <h2 className="text-h2 h2-custom  title">And Something for the CFO: ROI</h2>
                        <p className=" hw-des">
                            Spend less on new headcount/onboarding and instead, accelerate Time-to-Productivity. Super Hero agents are a click away.
                        </p>
                        {/* <AppButton {...buttonProps('Download')} linkTo='https://drive.google.com/file/d/1o3AD4DEZDraQF3DzzoBL6yL7Y4S7BUef/view?usp=drive_link' /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
