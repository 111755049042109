import React from 'react';
import AppLayout from '../../components/AppLayout';
import '../../styles/index.css';
import { imageDataType } from '../prevent-escalations';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import HomeVideo from '../../components/video-section';
import HowItWorks from '../../components/video-section/how-it-works-2';

function EmpowerAgentsAiBoostingProductivity({ data }: { data: imageDataType }) {
    const pageData = {
        seoData: {
            title: "Navigating Challenges and Future Trends in Customer Service",
            canonicalLink: "https://www.aptedge.io/technology",
            metaDescription: {
                metaDescription: `Check out the latest interview of Aakrit Prasad (Co-Founder & CEO) and Charles Coaxum, Former Head of Customer Success Security at Cisco, an early adopter of AptEdge on "The Future Of Customer Service".`,
            },
            openGraphImage: {
                file: {
                    url: "",
                },
            },
        },
    }

    return (
        <>
            <AppLayout seoData={pageData.seoData}>
                <HomeVideo />



                <HowItWorks />

                <CtaBlock ctaTitle={undefined} />
            </AppLayout>
        </>
    );
}

export default EmpowerAgentsAiBoostingProductivity;
