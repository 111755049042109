import React from "react"

//@ts-ignore
const HostVideo2 = ({ className, videoSrcURL, videoTitle, ...props }) => (
  <div className={className ?? "home-hero-video-wrapper"} >
  
    <iframe
      title="vimeo-player"
      src="https://player.vimeo.com/video/887155390?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
      width="845" height="360"
      frameBorder="0"
      allowFullScreen
    >
    </iframe>
   
  </div>
)
export default HostVideo2;